<template>
    <div class="chooseGroup">
        <el-row :gutter="10" style="margin-top: 16px">
            <el-col :span="3" v-for="(data, code) in groups" :key="code">
                <el-card>
                    <div class="group" @click="chooseGroup(code, data)">{{ data }}</div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { get } from 'request/http';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'ChooseGroup',
    data() {
        return {
            groups: [],
        };
    },
    created() {
        UrlUtils.QueryRemote(
            this,
            '/checkSession',
            (rst) => {
                if (rst['code']) {
                    this.$store.commit('setSession', {
                        code: rst['code'],
                        realName: rst['name'],
                        staffType: rst['staffType'],
                        deptType: rst['deptType'],
                        superStaffFlag: rst['superStaffFlag'],
                    });
                } else {
                    //未登录
                    this.$router.push({ name: 'login' });
                }
            },
            (errorMsg) => {
                this.$router.push({ name: 'login' });
            }
        );
    },
    mounted() {
        (async () => {
            this.groups = await get('/ajaxManagedAndBelongCompany', true);
        })();
    },
    methods: {
        async chooseGroup(code, name) {
            await get('/ajaxChooseCompany?chosenCompanyCode=' + code);
            await this.$router.push({ name: 'main', params: { groupName: name } });
        },
    },
};
</script>

<style scoped>
.chooseGroup {
    padding: 16px;
}

.chooseGroup .group {
    font-size: 20px;
    color: white;
    font-weight: bolder;
    cursor: pointer;
    height: 90px;
    background: #19c989;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
